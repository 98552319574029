<button
  type="button"
  class="!size-10 !border-none disabled:!border-none"
  mat-flat-button
  color="basic"
  [ngClass]="{
    '!bg-gray-100': active,
    '!opacity-0': hidden,
  }"
  [disabled]="hidden || disabled">
  <ng-content></ng-content>
</button>
