import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialRead } from '@cat-ai-us-fe/api';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { DocumentExtensions, ImageExtensions, VideoExtensions } from './config';

@Component({
  selector: 'cat-ai-material-preview',
  standalone: true,
  imports: [
    CommonModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    VideoPlayerComponent,
  ],
  templateUrl: './material-preview.component.html',
})
export class MaterialPreviewComponent implements OnChanges {
  @Input() url!: string | undefined;
  @Input() loading = false;
  @Output() handleError = new EventEmitter();
  fileType: string | undefined;
  rendering = false;

  public MaterialType = MaterialRead.TypeEnum;
  constructor(protected _sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url) {
      this.rendering = true;
      this.checkFileExtension();
    }
  }

  onSuccessRender() {
    this.rendering = false;
  }

  onError($event: any) {
    this.rendering = false;
    this.handleError.emit($event);
  }

  checkFileExtension(): void {
    this.rendering = false;
    const match = this.url?.match(/\.([a-zA-Z0-9]+)(?=\?|$)/);
    if (match) {
      const extension = match[1].toLowerCase();

      if (
        Object.values(VideoExtensions).includes(extension as VideoExtensions)
      ) {
        this.fileType = 'video';
      } else if (
        Object.values(DocumentExtensions).includes(
          extension as DocumentExtensions,
        )
      ) {
        this.fileType = 'document';
      } else if (
        Object.values(ImageExtensions).includes(extension as ImageExtensions)
      ) {
        this.fileType = 'image';
      }
    }
  }
}
