import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PaginationConfig } from '@cat-ai-us-fe/shared/util';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { PaginationButtonComponent } from '../pagination-button/pagination-button.component';

@Component({
  selector: 'cat-ai-table-footer',
  standalone: true,
  imports: [
    PaginationButtonComponent,
    FormsModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './table-footer.component.html',
})
export class TableFooterComponent implements OnChanges {
  @Input() totalCountLabel: string | undefined;
  @Input({ required: true }) pagination!: PaginationConfig;
  @Input() isLoading = false;

  @Output() pageChange = new EventEmitter<number>();
  @Output() perPageChange = new EventEmitter<number>();

  pagesCount = 0;

  readonly pagesDisplayCount = 4;
  pagesButtons: any[] = [];
  step = 1;

  ngOnChanges(): void {
    this.setPagination();
    this.makePages();
  }

  changePage(page: number) {
    this.pageChange.emit(page);
  }

  private setPagination() {
    this.pagesCount = Math.ceil(
      (this.pagination.itemsCount || 0) / this.pagination.perPage,
    );
  }

  // Generate array for displaying page buttons
  private makePages() {
    const buttonsCount = Math.min(this.pagesCount, this.pagesDisplayCount);

    this.pagesButtons = new Array(buttonsCount).fill(undefined).map((_, i) => {
      // Calculate page offset so that active page would stay in the middle
      const offset = Math.min(
        Math.max(1, this.pagination.page - this.pagesDisplayCount / 2),
        Math.max(1, this.pagesCount - this.pagesDisplayCount / 2 - 1),
      );
      const page = i + offset;
      return Math.min(page, this.pagesCount);
    });
  }
}
