import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cat-ai-pagination-button',
  standalone: true,
  imports: [NgClass, MatButtonModule],
  templateUrl: './pagination-button.component.html',
})
export class PaginationButtonComponent {
  @Input() hidden = false;
  @Input() active = false;
  @Input() disabled = false;

  @Output() pageSelect: EventEmitter<number> = new EventEmitter<number>();
}
