export enum VideoExtensions {
  MP4 = 'mp4',
  AVI = 'avi',
  MOV = 'mov',
  WMV = 'wmv',
  FLV = 'flv',
  MKV = 'mkv',
}

export enum DocumentExtensions {
  PDF = 'pdf',
}

export enum ImageExtensions {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  SVG = 'svg',
  WEBP = 'webp',
}
